import React from "react"
import {
  Box,
  Container,
  Flex,
  Heading,
  Text,
  Grid,
  Image,
} from "@theme-ui/components"
import { i18nContext } from "../../context/i18nContext"
import { InboundLink } from "../link"
import VideoThumb from "../videoThumb"
import SwiperCore, { Pagination, Navigation, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "../../assets/style/swiper-bundle.min.css"
import { getChannelPath, getLatestVideoPage } from "../../utils/path"
import { useBreakpointIndex } from "@theme-ui/match-media"
import RichContentStructuredText from "../richContentStructuredText"

const ChannelVideosSection = ({
  block,
  biogasVideos,
  biomethaneVideos,
  cogenerationVideos,
}) => {
  const { channel, description, logo } = block
  const { slug } = channel.pageLink
  var videos = []

  if (slug === "cogeneration-channel") videos = cogenerationVideos
  if (slug === "biomethane-rng-channel") videos = biomethaneVideos
  if (slug === "biogas-channel") videos = biogasVideos

  const index = useBreakpointIndex()
  return (
    <Box sx={{ position: "relative", my: [7] }}>
      <i18nContext.Consumer>
        {t => (
          <>
            <Container
              variant={index > 1 ? "oneSideRight" : "container"}
              sx={{}}
            >
              <Grid columns={["1fr", "1fr", ".3fr .7fr"]} gap={[0, 4]}>
                <Flex
                  sx={{
                    flexDirection: ["column", "column", "column"],
                    justifyContent: ["space-between", "space-between"],
                    alignItems: ["flex-start"],
                    mb: [5],
                  }}
                >
                  <Box
                    sx={{
                      img: {
                        maxHeight: ["50px", "50px", "74px"],
                      },
                    }}
                  >
                    <Image src={logo.url} />
                  </Box>
                  <Text
                    sx={{
                      maxWidth: "370px",
                      fontSize: [3, 4],
                    }}
                    as="div"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                  <Box>
                    <InboundLink
                      sx={{
                        backgroundColor: slug,
                      }}
                      variant="links.badge.full"
                      to={getChannelPath(
                        videos[0].category.channel.pageLink.slug,
                        videos[0].locale
                      )}
                    >
                      {t.visitWeb}
                    </InboundLink>
                  </Box>
                </Flex>
                {index > 1 ? (
                  <Box
                    sx={{
                      ".swiper-container": {
                        pb: [0],
                      },
                      ".swiper-slide": {
                        height: "auto",
                      },
                      ".swiper-slide:last-child": {
                        pr: [3, 4],
                      },
                    }}
                  >
                    <Grid
                      columns={[
                        "1fr .185fr",
                        "1fr 1fr .185fr",
                        "1fr 1fr .485fr",
                      ]}
                      sx={{ mb: [4] }}
                      gap={[3]}
                    >
                      <Box
                        sx={{ borderTop: "2px solid", borderColor: slug }}
                      ></Box>
                      <Box
                        sx={{ borderTop: "2px solid", borderColor: slug }}
                      ></Box>
                      <Box
                        sx={{
                          display: ["none", "block", "block"],
                          borderTop: "2px solid",
                          borderColor: slug,
                        }}
                      ></Box>
                    </Grid>
                    <Swiper
                      spaceBetween={16}
                      preventClicks={true}
                      preventClicksPropagation={true}
                      autoHeight={false}
                      breakpoints={{
                        320: {
                          slidesPerView: 1.2,
                        },
                        640: {
                          slidesPerView: 2.2,
                        },
                        896: {
                          slidesPerView: 2.5,
                        },
                      }}
                    >
                      {videos.map(video => (
                        <>
                          <SwiperSlide key={video.id}>
                            <VideoThumb video={video} small theme={slug} />
                          </SwiperSlide>
                        </>
                      ))}
                    </Swiper>
                  </Box>
                ) : (
                  <Grid columns={["1fr"]}>
                    {videos.slice(0,3).map(video => (
                      <VideoThumb video={video} theme={slug} />
                    ))}
                  </Grid>
                )}
              </Grid>
            </Container>
          </>
        )}
      </i18nContext.Consumer>
    </Box>
  )
}

export default ChannelVideosSection
