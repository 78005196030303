import React from "react"
import { Box, Container, Flex, Heading, Text, Grid } from "@theme-ui/components"
import { i18nContext } from "../context/i18nContext"
import { InboundLink, OutboundLink } from "./link"
import { MagicLink } from "../utils/magicLink"

const NewsletterSection = ({ data }) => {
  const { title, subtitle, link } = data

  return (
    <Box
      sx={{
        position: "relative",
        my: [4, 4, 7],
        mb: [0, 0, 0],
        py: [8, 8, 10],
        backgroundColor: "primary",
        color: "light",
      }}
    >
      <i18nContext.Consumer>
        {t => (
          <Container>
            <Grid
              sx={{
                flexDirection: "column",
                justifyContent: "space-between",
                alignContent: "center",
                alignItems: "center",
              }}
              columns={["1fr"]}
              gap={[8]}
            >
              <Box
                sx={{ textAlign: "center", m: ["0 auto"], maxWidth: "750px" }}
              >
                <Box sx={{}}>
                  <Heading
                    as="h2"
                    variant="h2"
                    color="light"
                    sx={{ fontSize: [6, 6], my: [0, 0] }}
                  >
                    {title}
                  </Heading>
                </Box>
                <Box sx={{}}>
                  <Heading
                    as="h2"
                    variant="h2"
                    color="dark"
                    sx={{ fontSize: [6, 6], my: [0, 0] }}
                  >
                    {subtitle}
                  </Heading>
                </Box>
              </Box>
              <Box sx={{ textAlign: "center" }}>
                <OutboundLink
                  href={link.url}
                  variant="links.badge.fullDark"
                  sx={{
                    display: "inline-block",
                  }}
                >
                  {link.anchor}
                </OutboundLink>
              </Box>
            </Grid>
          </Container>
        )}
      </i18nContext.Consumer>
    </Box>
  )
}

export default NewsletterSection
