import React from "react"
import { graphql } from "gatsby"
import { Box, Container } from "@theme-ui/components"
import Layout from "../components/layout"
import { getHomePath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { useFavicon } from "../hooks/useFavicon"
import { getPageSlug } from "../utils/pageSlug"
import RichContentStructuredText from "../components/richContentStructuredText"
import BannerList from "../components/blocks/bannerList"
import ChannelVideosSection from "../components/blocks/channelVideosSection"
import Sponsors from "../components/sponsors"
import NewsletterSection from "../components/newsletterSection"

const Home = ({
  data: {
    page,
    site,
    biogasVideos,
    biomethaneVideos,
    cogenerationVideos,
    sponsorSection,
    newsletterSection,
  },
}) => {
  const favicon = useFavicon().site.faviconMetaTags
  let tmpi18nPaths = []
  // console.log(sponsorSection)

  site.locales.map(locale => {
    if (locale !== "fr" && locale !== "de")
      tmpi18nPaths.push({
        locale: locale,
        value: getHomePath(locale),
        menuType: "home",
      })
  })

  const i18nPaths = tmpi18nPaths

  // console.log("page", getPageSlug(page))

  return (
    <Layout
      page={page}
      pageSlug={getPageSlug(page)}
      locale={page.locale}
      i18nPaths={i18nPaths}
    >
      <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
        <html lang={page.locale} />
        <meta
          property="og:image"
          content="https://www.datocms-assets.com/72518/1666151846-logo-netzero.jpg"
        />
      </HelmetDatoCms>

      <Box
        sx={{
          mt: ["100px", "100px", 0, 0],
          borderTop: ["1px solid", "1px solid", "0px solid"],
          borderColor: "dark",
        }}
      >
        <RichContentStructuredText text={page.body} />
      </Box>
      {page.content.map(
        block =>
          block.model &&
          block.model.apiKey && (
            <Box as="section" key={block.id}>
              {block.model.apiKey === "title_and_body" && (
                <Box>
                  <Container
                    sx={{
                      pt: [6, 8],
                      h1: {
                        pb: [5],
                        fontSize: [5, 8],
                        color: "dark",
                        fontWeight: "400",
                        strong: {
                          fontSize: [6, 8],
                          fontWeight: "400",
                          color: "primary",
                        },
                      },
                    }}
                  >
                    {block.content && (
                      <RichContentStructuredText text={block.content.body} />
                    )}
                  </Container>
                </Box>
              )}
              {block.model.apiKey === "banner_list" && (
                <Box>
                  <BannerList
                    banners={block.banners}
                    channel={"netzero-tube"}
                  />
                </Box>
              )}
              {block.model.apiKey === "channel_videos_section" && (
                <Box>
                  <ChannelVideosSection
                    block={block}
                    biogasVideos={biogasVideos.nodes}
                    biomethaneVideos={biomethaneVideos.nodes}
                    cogenerationVideos={cogenerationVideos.nodes}
                  />
                </Box>
              )}
            </Box>
          )
      )}
      {sponsorSection.nodes.length > 0 && (
        <Box>
          <Sponsors data={sponsorSection.nodes[0]} />
        </Box>
      )}
      {newsletterSection.nodes.length > 0 && (
        <Box>
          <NewsletterSection data={newsletterSection.nodes[0]} />
        </Box>
      )}
    </Layout>
  )
}

export default Home

export const query = graphql`
  query HomeQuery(
    $id: String!
    $locale: String!
    $biogasId: String!
    $biomethaneId: String!
    $cogenerationId: String!
  ) {
    page: datoCmsHomePage(id: { eq: $id }) {
      id
      title
      topBannerDescription
      body {
        value
        blocks {
          __typename
          ... on DatoCmsMarquee {
            id: originalId
            title
            link {
              ... on DatoCmsInternalLink {
                originalId
                anchor
                locale
                model {
                  apiKey
                }
                forceUrl
                link {
                  ... on DatoCmsEvent {
                    id
                    slug
                    model {
                      apiKey
                    }
                  }  
                  ... on DatoCmsHomePage {
                    locale
                    model {
                      apiKey
                    }
                  }
                  ... on DatoCmsBlogPage {
                    ...BlogDetails
                  }
                  ... on DatoCmsPage {
                    ...PageDetails
                    ...PageTreeParent
                    ...AllSlugLocales
                  }
                  ... on DatoCmsArticle {
                    ...ArticleDetails
                    ...ArticleAllSlugLocales
                  }
                  ... on DatoCmsArticleCategory {
                    ...ArticleCategoryDetails
                    ...ArticleCategoryAllSlugLocales
                  }
                  ... on DatoCmsVideoCategory {
                    ...VideoCategoryPageDetails
                  }
                }
              }
              ... on DatoCmsExternalLink {
                id
                anchor
                url
                model {
                  apiKey
                }
              }
            }
            model {
              apiKey
            }
          }
          ... on DatoCmsHomeChannelsHeader {
            id: originalId
            channels {
              id
              ... on DatoCmsPage {
                ...PageDetails
                ...PageTreeParent
                ...AllSlugLocales
              }
            }
          }
        }
        links {
          __typename
          ... on DatoCmsInternalLink {
            id: originalId
            anchor
            _allAnchorLocales {
              locale
              value
            }
            locale
            model {
              apiKey
            }
            forceUrl
            link {
              ... on DatoCmsEvent {
                id
                slug
                model {
                  apiKey
                }
              }
              ... on DatoCmsHomePage {
                locale
                model {
                  apiKey
                }
              }
              ... on DatoCmsBlogPage {
                ...BlogDetails
              }
              ... on DatoCmsPage {
                ...PageDetails
                ...PageTreeParent
                ...AllSlugLocales
              }
              ... on DatoCmsArticle {
                ...ArticleDetails
                ...ArticleAllSlugLocales
              }
              ... on DatoCmsArticleCategory {
                ...ArticleCategoryDetails
                ...ArticleCategoryAllSlugLocales
              }
              ... on DatoCmsVideoCategory {
                ...VideoCategoryPageDetails
              }
            }
          }
        }
      }
      locale
      content {
        ... on DatoCmsTitleAndBody {
          id
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsBannerList {
          banners {
            ... on DatoCmsBannerCollection {
              id
              title
              image {
                gatsbyImageData(width: 1480, placeholder: BLURRED)
              }
              url
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsChannelVideosSection {
          logo {
            url
          }
          description
          channel {
            ... on DatoCmsChannel {
              id
              pageLink {
                ... on DatoCmsPage {
                  id
                  slug
                }
              }
            }
          }
          model {
            apiKey
          }
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    biogasVideos: allDatoCmsVideo(
      limit: 10
      filter: {
        channel: { pageLink: { id: { eq: $biogasId } } }
        locale: { eq: $locale }
        slug: { ne: null }
      }
      sort: { fields: meta___firstPublishedAt, order: DESC }
    ) {
      nodes {
        id
        ...AllVideoSlugLocales
        slug
        title
        description
        videoId
        videoLink
        locale
        duration
        meta {
          firstPublishedAt
        }
        thumbnail {
          desktop: gatsbyImageData(placeholder: BLURRED, width: 600)
          mobile: gatsbyImageData(
            placeholder: BLURRED

            imgixParams: { fit: "crop", ar: "16:10", h: "800" }
          )
        }
        speaker {
          id
          name
          jobTitle
          company
        }
        category {
          id
          title
          locale
          slug
          model {
            apiKey
          }
          channel {
            pageLink {
              id
              slug
            }
          }
        }
      }
    }
    biomethaneVideos: allDatoCmsVideo(
      limit: 10
      filter: {
        channel: { pageLink: { id: { eq: $biomethaneId } } }
        locale: { eq: $locale }
        slug: { ne: null }
      }
      sort: { fields: meta___firstPublishedAt, order: DESC }
    ) {
      nodes {
        id
        ...AllVideoSlugLocales
        slug
        title
        description
        videoId
        videoLink
        locale
        duration
        meta {
          firstPublishedAt
        }
        thumbnail {
          desktop: gatsbyImageData(placeholder: BLURRED, width: 600)
          mobile: gatsbyImageData(
            placeholder: BLURRED

            imgixParams: { fit: "crop", ar: "16:10", h: "800" }
          )
        }
        speaker {
          id
          name
          jobTitle
          company
        }
        category {
          id
          title
          locale
          slug
          model {
            apiKey
          }
          channel {
            pageLink {
              id
              slug
            }
          }
        }
      }
    }
    cogenerationVideos: allDatoCmsVideo(
      limit: 10
      filter: {
        channel: { pageLink: { id: { eq: $cogenerationId } } }
        locale: { eq: $locale }
        slug: { ne: null }
      }
      sort: { fields: meta___firstPublishedAt, order: DESC }
    ) {
      nodes {
        id
        ...AllVideoSlugLocales
        slug
        title
        description
        videoId
        videoLink
        locale
        duration
        meta {
          firstPublishedAt
        }
        thumbnail {
          desktop: gatsbyImageData(placeholder: BLURRED, width: 600)
          mobile: gatsbyImageData(
            placeholder: BLURRED
            imgixParams: { fit: "crop", ar: "16:10", h: "800" }
          )
        }
        speaker {
          id
          name
          jobTitle
          company
        }
        category {
          id
          title
          locale
          slug
          model {
            apiKey
          }
          channel {
            pageLink {
              id
              slug
            }
          }
        }
      }
    }
    newsletterSection: allDatoCmsNewsletterSection(
      filter: {
        channel: { pageLink: { id: { eq: null } } }
        locale: { eq: $locale }
      }
    ) {
      nodes {
        title
        subtitle
        link {
          ... on DatoCmsExternalLink {
            id
            anchor
            url
            model {
              apiKey
            }
          }
        }
      }
    }
    sponsorSection: allDatoCmsSponsorSection(
      filter: { locale: { eq: $locale } }
    ) {
      nodes {
        sponsors {
          name
          image {
            url
            gatsbyImageData(
              height: 100
              placeholder: BLURRED
              imgixParams: { sat: -100 }
            )
          }
          url
        }
      }
    }
    site: datoCmsSite {
      locales
    }
  }
`
